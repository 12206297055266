import { Component, ViewChild, NgZone } from '@angular/core';

import { AlertController, Platform} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { UserService } from './api/user.service';
import { DatabaseHelperService } from './database/database-helper.service';
import { NavigationExtras, Router } from '@angular/router';
import { UserResponse } from './models/UserResponse';
import { Storage } from '@ionic/storage';
import { FCM } from '@ionic-native/fcm/ngx';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { lmsAllCourseInfo } from './models/lmsAllCourseInfo';
import { Login } from './models/Login';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { ByletspromisePage } from './buildyourself/byletspromise/byletspromise.page';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
 // @ViewChild(Nav, {static: false}) navChild:Nav;
  syncData: any = [];
  lang: any = "english";
  lang2: any  = "english";
  image: any;
  imageFlag = false;
  username = 'User';
  phno = '';
  multiRoleTypes = false;
  isStudent = false;
  isGuestUser = false;
  isFacilitator = false;
  isTeacher = false;
  isStar19 = false;
  lmsColorChange: any
  loginData
  islms
  show: boolean = false
  getStudentCourseinfoList: lmsAllCourseInfo[]
  level
  standard
  isWeb
  constructor(
    private alertController: AlertController,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private api: UserService,
    private dbHelper: DatabaseHelperService,
    private router: Router,
    private sharedPreference: Storage,
    private fcm: FCM,
    private ga: GoogleAnalytics
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
    
      // this.statusBar.styleDefault();
      this.isWeb = this.platform.platforms().indexOf("mobile") > -1 || this.platform.platforms().indexOf("android") > -1 ? false : true;
     // alert(this.platform.platforms())
      //alert("isWeb"+this.isWeb);
      //console.log("mobileweb ===>",this.platform.is('mobileweb'))
      this.api.readLang();
      console.log("Read lang was called in app component");
      this.sharedPreference.set('language','english')
      this.sharedPreference.set("english",{menu:"english",main:"english"})
      this.ga
        .startTrackerWithId('UA-147574087-1')
        .then(() => { })
        .catch((e) => {
          alert('Error starting GoogleAnalytics == ' + e)
        });
      this.ga.trackView('Main Page');
      this.fcm.subscribeToTopic('topicSubscription').then((d) => { });
      // this.sharedPreference.get('language').then((lang) => {
      //   this.sharedPreference.get(lang).then((l) => {
      //     if (l !== null) {
      //       this.lang = l.menu;
      //       this.lang2 = l.main;
      //     }
      //   });
      // });

      // this.sharedPreference.get('photo').then((p) => {
      //   if (p === null) {
      //     this.imageFlag = false;
      //   } else {
      //     this.image = p;
      //     this.imageFlag = true;
      //   }
      // });

      // this.dbHelper.getRoleTypeMappingData().then((rtm: any) => {
      //   const roleType = rtm.findIndex(x => x.RoleType === 'Facilitator');
      //   const roleType2 = rtm.findIndex(x => x.RoleType === 'Coordinator');
      //   if (roleType2 !== -1) {
      //     this.isFacilitator = true;
      //   } else {
      //     this.isFacilitator = false;
      //   }
      // });

      // this.sharedPreference.get('loginData').then((lD) => {
      //   if (lD !== null) {
      //     this.username = lD.Name;
      //     this.phno = lD.Mobile;
      //     this.isStudent = lD.Role === 'Guest Student' ? true : false;
      //     this.isGuestUser = lD.Role === 'Guest User' ? true : false;
      //   }
      // });

    


      if (this.platform.is('android')) {
        this.statusBar.backgroundColorByHexString('#F2811D');
        this.statusBar.hide();
      }
      this.splashScreen.hide();

      this.fcm.onNotification().subscribe((data) => {
        if (data.wasTapped) {
          console.log('Received in background: ' + data);
          this.sharedPreference.set('notificationData', data);
        } else {
          console.log('Received in foreground: ' + data);
          this.sharedPreference.get('loginData').then((lD) => {
            if (lD === null) {
            } else if (lD.Mobile && lD.Password) {
              if (this.router.url === '/main') {
                this.sharedPreference.get('language').then((lang) => {
                  this.sharedPreference.get(lang).then((l) => {
                    //this.api.notifyAlert(data, l.user_service.sync);
                  });
                });
              } else {
                // this.sharedPreference.set('notificationDataFg', data);
                this.sharedPreference.get('language').then((lang) => {
                  this.sharedPreference.get(lang).then((l) => {
                    this.api.notifyAlert2(data, l.user_service.sync);
                  });
                });
              }
            }
          });
        }
      });

      this.fcm.onTokenRefresh().subscribe((token) => {

        // Register your new token in your back-end if you want
        // backend.registerToken(token);
      });
    });
  }
  async presentAlert(msg) {
    const alert = await this.alertController.create({

      message: msg,
      buttons: ['OK']
    });

    await alert.present();

  }

  selectStandard() {
    this.router.navigateByUrl('/dashboard');

  }


  goToRecentCourse() {
    this.router.navigateByUrl('/all-courses');

  }

  selectLevel() {

    debugger
    if (this.standard == null || this.standard == undefined || this.standard == "") {
      this.presentAlert("Please select Standard")

    }
    else {
      let fromMenu:boolean
      fromMenu=true
      const navigationExtras: NavigationExtras = {
        queryParams: {
          fromMenu: fromMenu
        }
      };
      this.router.navigate(['levels'], navigationExtras);

    }

  }
  getStudentCourseinfo(RpgId) {
    debugger
    let req = {
      "RpgId": RpgId
    }

    this.api.getStudentcourseinfo(req).then((res: any) => {
      debugger
      console.log("lms course info succ", res)
      let data = res.course
      let data1 = res.result
      console.log("lms course info succ1", data)
      this.level = data1.currentLevel
      this.standard = data1.coursetype
      this.sharedPreference.get("loginData").then(res=>{
        let loginData = new Login()
        loginData=res
        loginData.level=this.level
        loginData.standard=this.standard
        this.sharedPreference.set("loginData",loginData)
     
      })
    }, err => {
      console.log("lms course info err", err)
    })

  }

  onMenuOpen() {
    debugger
    console.log("islms", this.islms)
    let url = this.router.url
    if (url.indexOf('?') > -1)
{
  let urlUpdated = url.substr(0, url.indexOf('?'))
  console.log("url", urlUpdated);
  console.log('this.router.url', this.router.url);
  if (urlUpdated == "/course-details" || urlUpdated == "/levels" || urlUpdated == "/dashboard") {
    this.show = true

  }
  else {
    this.show = false
  }
}
else{
  if (url == "/course-details" || url == "/levels" || url == "/dashboard") {
    this.show = true

  }
  else {
    this.show = false
  }
}
   
    this.sharedPreference.get('photo').then((p) => {
      if (p === null) {
        this.imageFlag = false;
      } else {
        this.image = p;
        this.imageFlag = true;
      }
    });

    this.sharedPreference.get('loginData').then((lD) => {
      if (lD !== null) {
        console.log("UserInfo", lD)
        this.username = lD.Name;
        this.phno = lD.Mobile;
        this.isStudent = lD.Role === 'Guest Student' ? true : false;
        this.isGuestUser = lD.Role === 'Guest User' ? true : false;
        this.getStudentCourseinfoList = new Array<lmsAllCourseInfo>()
        this.getStudentCourseinfo(lD.RpgId)

      }
    });
    this.dbHelper.getRoleTypeMappingData().then((rtm: any) => {
      /*** 
       * if role is facilitator, roleType is coordinator
       * if role is star2019, roleType is facilitator
       * if role is star2020 or teacher, roleType is teacher
      */
      const roleType = rtm.findIndex(x => x.RoleType === 'Facilitator');
      const roleType2 = rtm.findIndex(x => x.RoleType === 'Coordinator');
      const roleType3 = rtm.findIndex(x => x.RoleType === 'Teacher' || x.RoleType === 'Guest User');
      if (roleType !== -1) {
        this.isStar19 = true;
      } else {
        this.isStar19 = false;
      }
      if (roleType2 !== -1) {
        this.isFacilitator = true;
      } else {
        this.isFacilitator = false;
      }
      if (roleType3 !== -1) {
        this.isTeacher = true;
      } else {
        this.isTeacher = false;
      }
    });
    this.sharedPreference.get('language').then((lang) => {
      this.sharedPreference.get(lang).then((l) => {
        if (l !== null) {
          this.lang = l.menu;
          this.lang2 = l.main;
        }
      });
    });
  }

  getToken() {
    this.fcm.getToken().then((token) => {

      console.log(token);
      // Register your new token in your back-end if you want
      // backend.registerToken(token);
    });
  }

  Logout() {
    this.sharedPreference.clear();
    
    if (this.api.checkInternetConnection()) {
      // this.sharedPreference.get("loginData").then(res => {
      //   debugger
      //   this.loginData = res
      // })
      //this.api.syncData(true)
      this.router.navigateByUrl('/home');
    } else {
      alert('Internet Connection is required for Logout.');
    }
  }

  goToRoleSection(roleSection){
    if(roleSection.val == "learningsource"){
      alert("Coming soon!")
    }else{
      this.sharedPreference.set("selectedRoleSection",roleSection).then(()=>{
        this.router.navigateByUrl("/role-section");
        this.api.publishSomeData("data")
      });
    }
  }

  exit() {
    // tslint:disable-next-line: no-string-literal
    navigator['app'].exitApp();
  }

  refresh() {
    // this.database
    //   .getSyncData()
    //   .then((data) => {
    //     this.syncData = data;
    //     if (this.syncData.length <= 0) {
    //       this.api.refreshCall();
    //     } else {
    //       alert('Please sync the data !!!');
    //     }
    //   })
    //   .catch(function(error) {});
  }

  go_to_faq() {
    const obj = { flag: true };
    this.sharedPreference.set('faq-sharedPref', JSON.stringify(obj));
    this.router.navigateByUrl('/faq');
  }

  go_to_addprogram() {
    this.router.navigateByUrl('/add-program');
  }

  go_to_list_of_cohorts() {
    console.log(this.lang);
    this.router.navigateByUrl('/listofcohorts');
  }

  by() {
    this.router.navigateByUrl('/buildyourself');
  }
  itc() {
    this.router.navigateByUrl('/intheclassroom');
  }
  home() {
    this.router.navigateByUrl('/main');
    //this.router.navigateByUrl('/home');
  }

  letsWin() {
    this.router.navigateByUrl('/win');
  }

  feedback() {
    this.dbHelper.getRoleTypeMappingData().then((rtm: any) => {
      const roleType = rtm.findIndex(x => x.RoleType === 'Facilitator');
      const roleType2 = rtm.findIndex(x => x.RoleType === 'Coordinator');
      if (roleType !== -1 || roleType2 !== -1) {
        if (roleType !== -1 && roleType2 !== -1) {
          this.multiRoleTypes = true;
        }
        this.sharedPreference.set('schedule-details-route', JSON.stringify({ multiRoleTypes: this.multiRoleTypes }));
        this.router.navigateByUrl('/schedule-details');
      } else {
        this.router.navigateByUrl('/feedback');
      }
    });
  }

  LMS() {
    this.router.navigateByUrl('/dashboard');
  }
  LmsChange() {
    this.lmsColorChange = ""
  }
  LmsChangeclr() {
    this.lmsColorChange = "lms-header"
  }
}
